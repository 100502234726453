import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  id: "clients",
  class: "m-5 text-primary"
}
const _hoisted_2 = { class: "row mt-5" }
const _hoisted_3 = {
  class: "d-flex align-items-center justify-content-center",
  style: {"height":"200px"}
}
const _hoisted_4 = ["href"]
const _hoisted_5 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MDBAnimation = _resolveComponent("MDBAnimation")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("h1", { class: "mt-1 fs-1 fw-bold text-primary pt-5" }, "Featured Clients", -1)),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.clients, (client) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "col-12 col-sm-6 col-lg-3 px-5",
          key: client.id
        }, [
          _createVNode(_component_MDBAnimation, {
            trigger: "onScroll",
            animation: "slide-in-up"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("a", {
                  href: client.link,
                  target: "_blank",
                  rel: "noopener noreferrer"
                }, [
                  _createElementVNode("img", {
                    src: client.imageUrl,
                    alt: client.description,
                    class: "client-logo"
                  }, null, 8, _hoisted_5)
                ], 8, _hoisted_4)
              ])
            ]),
            _: 2
          }, 1024)
        ]))
      }), 128))
    ])
  ]))
}