
  import { defineComponent, ref } from 'vue';
  import Client from '@/types/client';
  import { MDBAnimation } from 'mdb-vue-ui-kit';

  export default defineComponent({
    name: 'FeaturedClients',
    components: {
      MDBAnimation,
    },
    setup() {
      const clients = ref<Client[]>([
        {
          imageUrl: require('../../src/assets/clients-logos/SCHS.png'),
          description: 'Saudi Commission For Health Specialties',
          id: '1',
          delay: 100,
          link: 'https://scfhs.org.sa/en/',
        },
        {
          imageUrl: require('../../src/assets/clients-logos/hla.png'),
          description: 'Healthcare Leadership Academy',
          id: '2',
          delay: 100,
          link: 'https://scfhs.org.sa/en/',
        },
        {
          imageUrl: require('../../src/assets/clients-logos/himam-logo.png'),
          description: 'Himam',
          id: '3',
          delay: 100,
          link: 'https://www.himam.com/',
        },
        {
          imageUrl: require('../../src/assets/clients-logos/expert-academy-logo.png'),
          description: 'Experts Academy',
          id: '4',
          delay: 100,
          link: 'https://www.expertsacademy.sa/',
        },
      ]);
      return {
        clients,
      };
    },
  });
