import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "contact",
  class: "bg-primary py-5"
}
const _hoisted_2 = { class: "row d-flex justify-content-center mx-5" }
const _hoisted_3 = { class: "col-lg-3 p-2" }
const _hoisted_4 = { class: "col-lg-3 p-2" }
const _hoisted_5 = { class: "col-lg-3 p-2" }
const _hoisted_6 = { class: "row d-flex justify-content-center mx-5" }
const _hoisted_7 = { class: "col-lg-9 p-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MDBInput = _resolveComponent("MDBInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[5] || (_cache[5] = _createElementVNode("h4", { class: "display-4 fw-bold text-light pt-5 pb-3 px-1" }, "Contact Us", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_MDBInput, {
          label: "First Name",
          type: "text",
          size: "lg",
          white: "",
          modelValue: _ctx.formsInfo.fname,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formsInfo.fname) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_MDBInput, {
          label: "Last Name",
          type: "text",
          size: "lg",
          white: "",
          modelValue: _ctx.formsInfo.lname,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formsInfo.lname) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_MDBInput, {
          label: "Email",
          type: "email",
          size: "lg",
          white: "",
          modelValue: _ctx.formsInfo.email,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formsInfo.email) = $event))
        }, null, 8, ["modelValue"])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_MDBInput, {
          label: "Message..",
          type: "text",
          size: "lg",
          white: "",
          modelValue: _ctx.formsInfo.comment,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formsInfo.comment) = $event))
        }, null, 8, ["modelValue"])
      ])
    ]),
    _createElementVNode("button", {
      type: "button",
      class: "btn btn-light text-primary fw-bold fs-5 mt-3 btn-lg shadow-0",
      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.SubmitrHandler()))
    }, " Send Message ")
  ]))
}