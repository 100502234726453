import { createElementVNode as _createElementVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../src/assets/logo.png'


const _hoisted_1 = { style: {"background-color":"#ffffff"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<div class=\"row border-top p-5\" data-v-29604ccd><div class=\"col-sm-6\" data-v-29604ccd><img src=\"" + _imports_0 + "\" alt=\"\" class=\"logo\" data-v-29604ccd></div><div class=\"col-sm-6 text-start\" data-v-29604ccd><h4 class=\"fs-4 pt-2\" data-v-29604ccd>Offices</h4><ul data-v-29604ccd><li class=\"fs-6\" data-v-29604ccd>8186 King Fahad Branch Rd., Riyadh, Saudi Arabia</li></ul></div></div><div class=\"py-3\" data-v-29604ccd>© 2022 Knowledge Pathway. All Rights Reserved.</div>", 2)
  ])))
}